import React from "react";

import { Flex, Text, Stack } from "flicket-ui";
import { PieChart as Chart, Pie, Cell } from "recharts";
import { LegendColor } from "./Legend";

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const percentage = (percent * 100).toFixed(0);

  if (Number(percentage) === 0) return null;

  return (
    <text
      x={x > cx ? x : x}
      y={y}
      fill="white"
      fontWeight="bold"
      textAnchor={x > cx ? "middle" : "middle"}
      dominantBaseline="central"
    >
      {`${percentage}%`}
    </text>
  );
};

export const PieChart = ({ data, label = null, total = null }) => {
  return (
    <Stack direction={{ _: "vertical", sm: "horizontal" }} gap={2}>
      <Flex justifyContent={"center"} position="relative">
        <Chart width={230} height={230}>
          <Pie
            cx={110}
            cy="50%"
            data={data}
            innerRadius={70}
            outerRadius={110}
            labelLine={false}
            // @ts-expect-error
            label={renderCustomizedLabel}
            dataKey="value"
          >
            {data?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </Chart>
        {(label || total) && (
          <Flex
            left={0}
            top={85}
            right={0}
            position="absolute"
            flexDirection="column"
            variant="center"
          >
            {total && (
              <Text as="span" fontSize={4} fontWeight="extraBold" color="N800">
                {total.toLocaleString()}
              </Text>
            )}

            {label && (
              <Text as="span" fontWeight="extraBold" color="N800">
                {label}
              </Text>
            )}
          </Flex>
        )}
      </Flex>

      <Stack
        flexWrap="wrap"
        justifyContent={{ _: "center", sm: "center" }}
        gap={2}
        direction={"vertical"}
      >
        {data?.map((item, index) => (
          <Flex alignItems="center" key={index}>
            <LegendColor bg={item.color} />
            <Text ml="3/4">{item.label}</Text>
          </Flex>
        ))}
      </Stack>
    </Stack>
  );
};
