import { FC } from "react";

import { Text, Flex, Divider, SystemProps } from "flicket-ui";
import { Dropdown, DropdownOption } from "~components";

export const Card: FC<
  {
    title: string;
    dropdown?: { title: string; options: DropdownOption[] };
  } & SystemProps
> = ({ title, dropdown, children, ...props }) => {
  return (
    <Flex
      flexDir="column"
      flexShrink={0}
      p={{ _: "6/4", md: 4 }}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      width={1}
      overflow="hidden"
      {...props}
    >
      <Flex justifyContent="space-between" alignItems="center" width={1}>
        <Text variant={"header.M"}>{title}</Text>

        {dropdown && (
          <Dropdown options={dropdown.options} zIndex={10}>
            {dropdown.title}
          </Dropdown>
        )}
      </Flex>
      <Divider mb={{ _: "6/4", xs: 4 }} mt="6/4" />

      {children}
    </Flex>
  );
};
