import { Stack, Text } from "flicket-ui";
import { PercentBar } from "~components/common/PercentBar";
import { ScanVisitorsQuery } from "~graphql/sdk";

type ScansByZoneProps = {
  data: ScanVisitorsQuery["scanVisitors"][number];
};

export const ScansByZone = ({ data }: ScansByZoneProps) => {
  const expected =
    data.items.find((item) => item.name === "Expected")?.value ?? 0;
  const scanned =
    data.items.find((item) => item.name === "Scanned")?.value ?? 0;
  const percentage = Math.round((scanned / expected) * 100) ?? 0;

  if (expected === 0) return null;

  return (
    <Stack
      direction={{ _: "vertical", sm: "horizontal" }}
      justifyContent={"space-between"}
      alignItems={{ _: "flex-start", sm: "center" }}
      mb={3}
    >
      <Text
        variant="header.S"
        flex={{ _: undefined, sm: 2 }}
        mr={{ _: 0, sm: 3 }}
        mb={{ _: 1, sm: 0 }}
        whiteSpace={"nowrap"}
        style={{ textOverflow: "ellipsis" }}
        overflow="hidden"
        maxWidth={{ _: "100%", sm: undefined }}
      >
        {data.identifier}
      </Text>
      <Text
        mr={{ _: 0, sm: 5 }}
        mb={{ _: 1, sm: 0 }}
        flex={1}
        variant="regular"
      >
        {percentage}
        {`% scanned`}
      </Text>
      <PercentBar
        percentage={percentage}
        flex={{ _: undefined, sm: 2 }}
        mr={{ _: 0, sm: 5 }}
        mb={{ _: 1, sm: 0 }}
      />
      <Text flex={2} variant="regular">
        {scanned} of {expected} • {expected - scanned} yet to scan
      </Text>
    </Stack>
  );
};
