import { Box, SystemProps } from "flicket-ui";
import { pick } from "@styled-system/props";

type BarProps = SystemProps & {
  percentage: number;
  barColour?: SystemProps["backgroundColor"];
};

export const PercentBar = ({
  percentage,
  barColour = "N800",
  ...props
}: BarProps) => (
  <Box
    height={3}
    width="100%"
    backgroundColor="N100"
    {...pick(props)}
    borderRadius="full"
  >
    <Box
      height="100%"
      width={`${percentage}%`}
      backgroundColor={barColour}
      borderRadius="full"
    />
  </Box>
);
