import React from "react";

import { Box, Container, Flex, Text } from "flicket-ui";
import { Icon, Status } from "~components";
import { Card, Grid } from "~components/reports";
import { useIsMobile } from "~hooks";
import { ScanTicketTypesQuery } from "~graphql/sdk";
import { GraphQLError } from "graphql";
import { IconName } from "~components/common/Icon/Icon";

const getData = (
  data: ScanTicketTypesQuery["scanTicketTypes"],
  key: OverviewItemKey
) => {
  let currentData:
    | ScanTicketTypesQuery["scanTicketTypes"][number]
    | { items: { value: number }[] };

  if (key === "total") {
    currentData = data
      ?.filter((item) => item.identifier !== "Add-ons")
      ?.reduce(
        (acc, curr) => ({
          items: [
            { value: acc?.items?.[0]?.value + curr?.items?.[0]?.value },
            { value: acc?.items?.[1]?.value + curr?.items?.[1]?.value },
          ],
        }),
        { items: [{ value: 0 }, { value: 0 }] }
      );
  }

  if (key === "comps") {
    currentData = data?.find((d) => d.identifier === "Comp");
  }

  if (key === "members") {
    currentData = data?.find((d) => d.identifier === "Membership");
  }

  if (key === "sold") {
    currentData = data?.find((d) => d.identifier === "Sold Tickets");
  }

  if (key === "addons") {
    currentData = data?.find((d) => d.identifier === "Add-ons");
  }

  return (
    <>
      Issued: {currentData?.items[0].value.toLocaleString()}
      <br />
      Scanned: {currentData?.items[1].value.toLocaleString()}
      <br />
      To come:{" "}
      {Math.max(
        0,
        currentData?.items[0].value - currentData?.items[1].value
      ).toLocaleString()}
    </>
  );
};

type ScansOverviewProps = {
  data: ScanTicketTypesQuery["scanTicketTypes"];
  error: GraphQLError;
};

type OverviewItemKey = "total" | "comps" | "members" | "sold" | "addons";

type OverviewItem = {
  title: string;
  icon: IconName;
  key: OverviewItemKey;
};

const overviewItems: OverviewItem[] = [
  { title: "Total tickets", icon: "trending_up", key: "total" },
  { title: "Comps", icon: "credit_card", key: "comps" },
  { title: "Members", icon: "my-tickets", key: "members" },
  { title: "Sold", icon: "bars", key: "sold" },
  { title: "Add-ons", icon: "my-tickets", key: "addons" },
];

export const ScansOverview = ({ data, error }: ScansOverviewProps) => {
  const isMobile = useIsMobile();

  return (
    <Card
      title="Scans overview"
      mb={4}
      mt={4}
      pr={{ _: 0, md: 4 }}
      position="relative"
    >
      <Status loading={!error && !data} error={error}>
        {isMobile ? (
          <Container>
            {overviewItems.map(({ title, icon, key }) => (
              <Box
                bg="P100"
                borderRadius="sm"
                p={2}
                flex={1}
                key={title}
                mr={{ _: "6/4", md: 0 }}
                minWidth={{ _: 260, md: 220 }}
                mb={2}
              >
                <Flex alignItems="center" mb={2}>
                  <Flex
                    as="span"
                    borderRadius="full"
                    bg="P300"
                    width={32}
                    height={32}
                    variant="center"
                    mr={1}
                  >
                    <Icon icon={icon} color="white" fontSize={4} />
                  </Flex>
                  <Text fontWeight="extraBold" color="N500">
                    {title}
                  </Text>
                </Flex>
                <Text fontWeight="extraBold" fontSize={6} color="N600">
                  {getData(data, key) ?? "-"}
                </Text>
              </Box>
            ))}
          </Container>
        ) : (
          <Grid>
            {overviewItems.map(({ title, icon, key }) => (
              <Box
                bg="P100"
                borderRadius="sm"
                p={2}
                flex={1}
                key={title}
                mr={{ _: "6/4", md: 0 }}
                minWidth={{ _: 260, md: 220 }}
              >
                <Flex alignItems="center" mb={2}>
                  <Flex
                    as="span"
                    borderRadius="full"
                    bg="P300"
                    width={32}
                    height={32}
                    variant="center"
                    mr={1}
                  >
                    <Icon icon={icon} color="white" fontSize={4} />
                  </Flex>
                  <Text fontWeight="extraBold" color="N500">
                    {title}
                  </Text>
                </Flex>
                <Text fontWeight="extraBold" fontSize={6} color="N600">
                  {getData(data, key) ?? "-"}
                </Text>
              </Box>
            ))}
          </Grid>
        )}
      </Status>
    </Card>
  );
};

export default ScansOverview;
