import { Stack } from "flicket-ui";
import { GraphQLError } from "graphql";
import { Divider, Status } from "~components";
import { ScanVisitorsQuery } from "~graphql/sdk";
import { Card } from "../Card";
import { ScansByZone } from "./ScansByZone";

type ScanBreakdownProps = {
  isLoading: boolean;
  error: GraphQLError;
  scanVisitors: ScanVisitorsQuery["scanVisitors"];
};

export const ScanBreakdown = ({
  scanVisitors,
  error,
  isLoading,
}: ScanBreakdownProps) => {
  return (
    <Card title="Scanning breakdown" mb={4}>
      <Status loading={isLoading} error={error}>
        {scanVisitors?.length > 0 ? (
          <Stack direction={"vertical"}>
            {scanVisitors?.map((item) => (
              <ScansByZone key={item.identifier} data={item} />
            ))}
          </Stack>
        ) : (
          "Nothing scanned yet."
        )}
      </Status>

      <Divider my={3} />
    </Card>
  );
};
