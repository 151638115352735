import React, { FC } from "react";
import { Flex, Text, TransparentButton } from "flicket-ui";
import styled from "styled-components";

import { Icon } from "~components";
import { hex2rgba } from "~lib/helpers";

export const LegendColor = styled(Flex).attrs({
  as: "span",
  borderRadius: "xs",
  width: 16,
  height: 16,
  minWidth: 16,
  minHeight: 16,
})`
  box-shadow: 0px 4px 8px ${({ bg }) => hex2rgba(bg, 0.18)},
    0px 0px 2px ${({ bg }) => hex2rgba(bg, 0.12)},
    0px 0px 1px ${({ bg }) => hex2rgba(bg, 0.04)};
`;

interface Props {
  payload: {
    dataKey: string;
    fill: string;
    name: string;
  }[];
  inactiveItems: string[];
  onToggle: (key: string) => void;
}

export const Legend: FC<Props> = ({ payload, onToggle, inactiveItems }) => {
  return (
    <Flex
      as="ul"
      flexWrap="wrap"
      alignItems="center"
      justifyContent={{ _: "center", md: "flex-start" }}
    >
      {payload.map((entry, index) => (
        <Flex
          as="li"
          variant="center"
          key={`item-${index}`}
          bg={{ md: "N100" }}
          border={{ md: "1px" }}
          borderColor={{ md: "N300" }}
          borderRadius="full"
          mr="6/4"
          mb="6/4"
          px={{ md: 1 }}
          py={{ md: "3/4" }}
          flexShrink={0}
        >
          <TransparentButton
            onClick={() => {
              onToggle(entry.dataKey);
            }}
            px={1}
            borderRadius="none"
            borderRight="1px"
            borderColor={{ md: "N300" }}
            display={{ _: "none", md: "block" }}
          >
            <Icon
              icon={inactiveItems.includes(entry.dataKey) ? "check" : "cross"}
              color="N500"
              fontSize={1}
            />
          </TransparentButton>

          <LegendColor
            bg={entry.fill as any}
            mx={{ _: 0, md: "3/4" }}
            mr={{ _: "1/2" }}
          />
          <Text lineHeight="normal" fontSize={{ _: 1, md: 3 }}>
            {entry.name}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};
